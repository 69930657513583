import React, { FunctionComponent } from 'react'
import { PageProps, graphql, navigate } from 'gatsby'
import moment from 'moment'
import business from 'moment-business'
import { Box, Button, Container, Flex, Grid, Heading } from '@chakra-ui/react'

import { Layout } from '../../components/layout'
import { ProductList } from '../../components/productList'
import { WpCourse, WpSimpleProductConnection } from '../../../graphql-types'
import { FlexibleContent } from '../../components/flexibleContent/FlexibleContent'

interface IDataProps {
  wpCourse: WpCourse
  allWpSimpleProduct: WpSimpleProductConnection
}

const CourseTemplate: FunctionComponent<PageProps<IDataProps>> = ({ data }) => {
  const {
    wpCourse: { flexibleContent, id, title },
    allWpSimpleProduct: { edges },
  } = data

  const upcomingCourses = edges
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.courseType?.id === id
    })
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.bookingType !== 'group'
    })
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.sessions
        ? moment(courseDetails.sessions[0]?.startTime) >
            business.addWeekDays(moment(), 3)
        : false
    })

  const groupBookings = edges
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.courseType?.id === id
    })
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.bookingType === 'group'
    })

  return (
    <Layout>
      <Container maxW="4xl">
        <Grid gridGap="16">
          <Heading as="h1" size="xl">
            {title}
          </Heading>
          <Grid gridGap="8">
            <FlexibleContent {...flexibleContent} />
          </Grid>
          <Grid gridGap="8">
            {upcomingCourses.length ? (
              <>
                <Heading size="lg">Upcoming dates</Heading>
                <Box backgroundColor="whiteAlpha.900" borderRadius="2" p="4">
                  <Grid gridGap="4">
                    {upcomingCourses.slice(0, 2).map(({ node }, index) => {
                      return <ProductList key={index} {...node} />
                    })}
                    <Flex justifyContent="flex-end">
                      <Button
                        color="msuGreen.400"
                        colorScheme="aqua"
                        minW="32"
                        onClick={() => {
                          navigate(`/products?course=${id}`)
                        }}
                        variant="outline"
                      >
                        Find more dates
                      </Button>
                    </Flex>
                  </Grid>
                </Box>
              </>
            ) : null}
            {groupBookings.length ? (
              <>
                <Heading size="lg">Group Bookings</Heading>
                <Box backgroundColor="whiteAlpha.900" borderRadius="2" p="4">
                  <Grid gridGap="4">
                    {groupBookings.map(({ node }, index) => {
                      return (
                        <ProductList
                          hasBooking={false}
                          isLast={index === groupBookings.length - 1}
                          key={index}
                          {...node}
                        />
                      )
                    })}
                  </Grid>
                </Box>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default CourseTemplate

export const query = graphql`
  query wpCourse($id: String) {
    wpCourse(id: { eq: $id }) {
      flexibleContent {
        content {
          ... on WpCourse_Flexiblecontent_Content_Simple {
            align
            content {
              ... on WpCourse_Flexiblecontent_Content_Simple_Content_Paragraph {
                fieldGroupName
                text
              }
              ... on WpCourse_Flexiblecontent_Content_Simple_Content_List {
                fieldGroupName
                listItems {
                  fieldGroupName
                  listItem
                }
              }
            }
            fieldGroupName
            heading
            headingStyle
            link
            subheading
            subheadingStyle
            text
          }
        }
      }
      id
      slug
      title
    }
    allWpSimpleProduct(sort: { fields: courseDetails___sessions___startTime }) {
      edges {
        node {
          databaseId
          id
          link
          name
          onSale
          price
          regularPrice
          salePrice
          slug
          courseDetails {
            courseType {
              ... on WpCourse {
                id
                slug
                title
              }
            }
            sessions {
              duration
              startTime
            }
            bookingType
            locationShortname
            presentationMethod
          }
        }
      }
    }
  }
`
